<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/question' }">
        <app-i18n code="entities.question.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
                code="entities.question.preview.title"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="preview-area analyze-false" v-if="!loading">
      <el-form
              :label-position="labelPosition"
              :label-width="labelWidthForm"
              @submit.prevent.native
              class="form"
              v-if="record"
      >
        <div class="m-preview">
          <div class="pre-head">
            <h1> Preview: Speaking - {{ typeLabel }} </h1>
            <!--       <span class="p-pager">1 / 1</span>-->
          </div>
          <div class="pre-content">
            <component
                    :record="record"
                    :isAnalyze="false"
                    :description="typeDescription"
                    :key="type"
                    v-bind:is="type"
            />
          </div>
          <div class="pre-footer"></div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { QuestionModel } from '@/modules/question/question-model';
import readAloud from './preview/readAloud';
import repeatSentence from './preview/repeatSentence';
import describeImage from './preview/describeImage';
import retellLecture from './preview/retellLecture';
import answerShortQuestion from './preview/answerShortQuestion';
import summarizeWrittenText from './preview/summarizeWrittenText';
import writeEssay from './preview/writeEssay';
import singleOption from './preview/singleOption.vue';
import multipleOption from './preview/multipleOption.vue';
import reorderParagraph from './preview/reorderParagraph.vue';
import fillInTheBlanks from './preview/fillInTheBlanks.vue';
import selectedFillInTheBlanks from './preview/selectedFillInTheBlanks.vue';
import highlightCorrectSummary from './preview/highlightCorrectSummary.vue';
import summarizeSpokenText from './preview/summarizeSpokenText.vue';
import multipleAnswer from './preview/multipleAnswer.vue';
import writeInTheBlanks from './preview/writeInTheBlanks.vue';
import singleAnswer from './preview/singleAnswer.vue';
import selectMissingWord from './preview/selectMissingWord.vue';
import highlightIncorrectWords from './preview/highlightIncorrectWords';
import writeFromTheDictation from './preview/writeFromTheDictation.vue';

export default {
  name: 'question-preview-page',
  components: {
    readAloud,
    repeatSentence,
    describeImage,
    retellLecture,
    answerShortQuestion,
    summarizeWrittenText,
    writeEssay,
    singleOption,
    multipleOption,
    reorderParagraph,
    fillInTheBlanks,
    selectedFillInTheBlanks,
    highlightCorrectSummary,
    summarizeSpokenText,
    multipleAnswer,
    writeInTheBlanks,
    singleAnswer,
    selectMissingWord,
    highlightIncorrectWords,
    writeFromTheDictation,
    // multipleAnswer,
    // writeInTheBlanks,
    // singleAnswer,
  },
  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      hasAdminRole: 'auth/hasAdminRole',
      record: 'question/view/record',
      type: 'question/view/type',
      typeLabel: 'question/view/typeLabel',
      loading: 'question/view/loading',
      typeDescription: 'question/view/typeDescription',
    })
  },
  async created() {
    await this.doFind(this.$route.params.id);
  },
  methods: {
    ...mapActions({
      doFind: 'question/view/doFind',
    }),

    presenter(record, fieldName) {
      return QuestionModel.presenter(record, fieldName);
    },
  },
};
</script>

<style>
.preview-area {
  background-color: white;
  padding: 1rem;
  border: 1px solid #efefef;
  position: relative;
}

.preview-area > h1 {
  margin-top: 0;
  text-align: center;
  font-size: 24px;
}
.preview-area.analyze-false {
  width: 50%;
  margin: auto;
}
.preview-area.analyze-false > h1 {
  text-align: left;
}

.bold {
  font-weight: bold;
}

.mt-10 {
  margin-top: 10px;
}

.subcontent-drag-options li {
  background-color: white;
}

.tool-src {
  display: flex;
  justify-content: center;
}

.subcontent.highlight-options {
  width: 50%;
  text-align: left;
  padding-top: 15px;
}

.subcontent.highlight-options > div {
  margin-bottom: 15px;
  padding: 8px;
  cursor: pointer;
  background-color: white;
  border: 1px solid #efefef;
}

.clear-ul {
  padding-left: 0;
}

.lined-options p {
  line-height: 2;
  text-align: left;
  text-indent: 15px;
}

span.lined {
  background-color: #a51e36;
  color: white;
  padding: 4px;
}

select.select-options {
  background-color: white;
  color: #a51e36;
  padding: 4px 12px;
  border: 1px solid #a51e36;
  border-radius: 4px;
}

.lh-2 {
  line-height: 2;
}

span.correct-fill {
  padding: 0.25rem;
  background-color: #a51e36;
  color: white;
  border-radius: 0.25rem;
}

.single-preview .subtitle {
  position: absolute;
  right: 30px;
  top: 5px;
}
.subtitle > h4 {
  color: #a61f36;
}
.section-content > p {
  font-size: 14px;
  line-height: 1.4;
}

.lh2 {
  line-height: 2;
}

.section-title {
  font-weight: bold;
}

.w-50 {
  width: 50% !important;
}

.section-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #efefef;
  padding-top: 10px;
}

.section-footer > div {
  font-size: 14px;
  padding-left: 10px;
  padding-right: 1px;
}

.text-center {
  text-align: center;
}

.all-options {
  display: flex;
  justify-content: center;
}

.all-options > div {
  padding: 8px;
}

.all-options span {
  display: block;
  text-align: center;
  background-color: #fbfbfb;
  padding: 5px 8px;
  height: 28px;
  font-size: 14px;
  line-height: 28px;
  color: #a51e36;
  border: 1px solid #efefef;
  border-radius: 8px;
  font-weight: bold;
}

.section-footer > div > span:first-child {
  font-weight: 500;
  padding-right: 5px;
}

.preview-area {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  border: none;
  padding: 1.5rem;
  width: 70% !important;
  margin-top: 2rem !important;
}

.pre-head > h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
}

.subcontent-options > ul > li.active {
  color: #a51e36;
  border-color: #a51e36;
}

.text-left {
  text-align: left !important;
}

.single-preview h3 {
  font-size: 15px;
  text-align: center;
}

.subcontent-options > ul {
  list-style-type: none;
}

.subcontent-options > ul > li {
  padding: 8px;
  margin-bottom: 12px;
  font-size: 15px;
  border: 1px solid #efefef;
}

.pre-head {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background: linear-gradient(60deg, #da3553, #8f1a2f);
  color: white;
  border-radius: 0.75rem;
  margin-bottom: 1.5rem;
  -webkit-box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(165, 30, 54, 0.6);
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(165, 30, 54, 0.6);
}

.subhead {
  text-align: center;
  width: 76%;
  margin: auto;
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.pre-tool {
  width: 400px;
  margin: auto;
  margin-top: 3rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.tool-top {
  padding: 0.75rem;
  text-align: center;
  width: 300px;
  background: linear-gradient(60deg, #66bb6a, #388e3c);
  color: white;
  margin: auto;
  margin-top: -2rem;
  border-radius: 0.75rem;
  -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(76, 175, 80, 0.6);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(76, 175, 80, 0.6);
}

.tool-value {
  height: 3rem;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.tool-top > span {
  padding-left: 1rem;
}

.subcontent {
  padding: 0.5rem 1rem;
  text-align: center;
  background-color: #f4f4f461;
  border: 1px solid #efefef;
  width: 90%;
  margin: auto;
  margin-top: 2rem;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
}

.single-preview.preview-repeat-sentence .subcontent {
  width: 400px;
  padding-top: 0.75rem;
}

.image-preview {
  width: 50%;
  margin: auto;
  margin-top: 3rem;
}

.subcontent.highlight-options > div.active {
  border-color: #a51e36;
  color: white;
  background-color: #a51e36;
}
</style>
